@use '@angular/material' as mat;

@include mat.core();
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$her-primary: mat.define-palette(mat.$yellow-palette, 400);
$her-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$her-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".

$primary: mat.get-color-from-palette($her-primary);
$accent: mat.get-color-from-palette($her-accent);
$warn: mat.get-color-from-palette($her-warn);
