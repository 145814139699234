@use 'variables';
@use 'theme';
@use 'sass:color';
@use '@angular/material' as mat;
@use 'fonts';

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/maps';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/utilities';
@import 'bootstrap/scss/root';
@import 'bootstrap/scss/reboot';
@import 'bootstrap/scss/type';

@import 'bootstrap/scss/carousel';

@import 'bootstrap/scss/utilities/api';

.text-center {
  text-align: center;
}
:root {
  --bs-font-sans-serif: Roboto, 'Segoe UI', sans-serif;
  --bs-font-monospace: 'Fira Code', SFMono, Consolas, monospace;
}
* {
  scroll-behavior: smooth;
}

.desktop .menu-grid .mat-card {
  max-width: 16vw;
}
.mat-mdc-tab-labels {
  margin-bottom: 10px;
}

.mobile footer {
  width: calc(100% - 40px);
}

body[data-scroll='0'] app-navbar .mat-toolbar,
body[data-scroll='1'] app-navbar .mat-toolbar,
body[data-scroll='2'] app-navbar .mat-toolbar,
body[data-scroll='3'] app-navbar .mat-toolbar,
body[data-scroll='4'] app-navbar .mat-toolbar,
body[data-scroll='5'] app-navbar .mat-toolbar {
  box-shadow: none !important;
  margin-top: 20px !important;
  border-radius: 16px !important;
  width: calc(100vw - 40px);
}
app-navbar .mat-toolbar {
  transition: all 0.25s ease;
  transition: border-radius 0.25s ease !important;
  transition: margin-top 0.25s ease !important;
  transition: width 0.25s ease !important;
  border-radius: 0 !important;
  width: 100vw;
  margin-top: 0 !important;
}

mat-card.mat-card {
  display: grid;
  place-items: center;
}
.mobile .footer-grid {
  display: flex !important;
  text-align: center !important;
  flex-direction: column !important;
  align-items: center !important;
  align-content: center !important;
}
.desktop #carouselExampleIndicators {
  margin-left: 200px;
  margin-right: 200px;
}
body {
  color: rgba(0, 0, 0, 0.87);
}
::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f555;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  $thumb-color: #f48fb1;
  background-color: $thumb-color;
  &:hover {
    background-color: darken($thumb-color, 10);
  }
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  border-radius: 10px;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-image: none;
  padding: 5px;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.7);
  height: 50px;
  width: 50px;
}
.carousel-control-prev-icon::before {
  font-family: 'Material Icons';
  content: '\e2ea';
  font-size: 36px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.carousel-control-next-icon::before {
  font-family: 'Material Icons';
  content: '\e5e1';
  font-size: 36px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

::selection {
  background: variables.$accent;
  color: white;
}
::-moz-selection {
  background: variables.$accent;
  color: white;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', 'Segoe UI', sans-serif;
}

[color='dark'] {
  color: #200f09;
}

.material-icons.md-dark {
  color: #200f09;
}
a {
  color: variables.$accent;
  text-decoration: none;
  &:hover {
    color: variables.$accent;
    text-decoration: underline;
  }
}

.mat-list-item-content {
  margin-bottom: 5px;
}
.mat-card-header .mat-card-title {
  margin-top: 10px;
}
.mat-card > :last-child:not(.mat-card-footer),
.mat-card-content > :last-child:not(.mat-card-footer) {
  margin: 10px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s;
}

.mat-warn {
  color: variables.$warn;
}
.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important; /* 2 */
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important; /* 3 */
}
// @media only screen and (max-width: 1200px) {
//   body.desktop #carouselExampleIndicators {
//     margin: 30px !important;
//   }
// }
.mat-card {
  border-radius: 12px !important;
}
.mat-accordion .mat-expansion-panel:first-of-type {
  border-radius: 12px !important;
}
.mat-accordion .mat-expansion-panel {
  border-radius: 12px !important;
}
.mat-expansion-panel-spacing {
  margin: 5px 0 !important;
}
.mat-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button {
  border-radius: 18px !important;
}
a.mat-button,
a.mat-icon-button,
a.mat-stroked-button,
a.mat-flat-button {
  text-decoration: none !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Google Sans' !important;
}
.mat-mdc-icon-button {
  padding: 0;
  min-width: 0;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  line-height: 40px;
}
