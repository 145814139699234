@font-face {
  font-family: 'Google Sans';
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/googlesans/v36/4UabrENHsxJlGDuGo1OIlLU94YtzCwZsPF4o.woff2)
    format('woff2');
}
@font-face {
  font-family: 'Google Sans';
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/googlesans/v36/4UabrENHsxJlGDuGo1OIlLU94YtzCwZsPF4o.woff2)
    format('woff2');
}
